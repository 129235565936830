<template>
  <v-list>
    <v-divider></v-divider>
    <v-subheader>POS Info</v-subheader>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Device Name</v-list-item-title>
        <v-list-item-subtitle>{{ posName || "" }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Peers</v-list-item-title>
        <v-list-item-subtitle>{{ posPeers }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Device User</v-list-item-title>
        <v-list-item-subtitle>{{ deviceUser }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Device UUID</v-list-item-title>
        <v-list-item-subtitle>{{ deviceId }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title>Device Agent</v-list-item-title>
        <v-list-item-subtitle>{{ userAgent }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      posName: null,
      posPeers: null,
    };
  },
  computed: {
    deviceId() {
      return this.$store.state.auth.deviceId || "UNKNOWN";
    },
    deviceUser() {
      return this.$store.state.auth.email.split("@")[0];
    },
    userAgent() {
      return navigator.userAgent;
    },
  },
  async mounted() {
    this.posName = await this.$raiPos.computerName();

    this.posPeers = this.$raiPos
      .availablePosDevices()
      .map(({ name }) => name)
      .join(", ");
  },
};
</script>
